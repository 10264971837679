.job-list-container{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 50px;
}

.job-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px;
  }
  
.job-card {
  width: 300px;
  position: relative;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  animation: fadeInUp 0.5s ease-out forwards;  /* Customize the timing */
  opacity: 0;  /* Start with opacity 0 */
  transform: translateY(20px);  /* Start from slightly below */
  transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s ease;  /* Smooth transition when hovering */
}

.job-card .posted-date {
  font-size: 12px;
  color: #666;
  position: absolute;
  bottom:0px;
  right: 0px;
}

.job-card .action-buttons{
  position: absolute;
  bottom:0px;
  right: 0px;
}

.job-card .action-buttons .view-button, .apply-button{
  color: black;
  text-decoration: none;
}

.job-card .action-buttons .apply-button:hover{
  text-decoration: none;
  color: blue;
  font-size: 120%;
}

.job-card .action-buttons .view-button:hover{
  text-decoration: none;
  color: blue;
  font-size: 120%;
}

.job-card:hover {
  transform: translateY(-5px);
  /* background-color: #f0f0f0; */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.Modal__Overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

/* Modal fade-in effect */
.modal-content {
  animation: fadeInModal 0.3s ease-out forwards;
  opacity: 0;
}

@keyframes fadeInModal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Basic animation for job cards */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);  /* Start below the normal position */
  }
  100% {
    opacity: 1;
    transform: translateY(0);  /* End in the normal position */
  }
}

/* Apply the animation to the job cards */
.job-card:nth-child(odd) {
  animation-delay: 0.2s;  /* Stagger animation */
}

.job-card:nth-child(even) {
  animation-delay: 0.4s;  /* Stagger animation */
}

.modal-body h2 {
  font-size: 22px;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.modal-body p {
  font-size: 13px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
}

.modal-body ul {
  list-style-type: disc;
  padding-left: 20px; /* Ensure there's space for bullet points */
}

.modal-body li {
  font-size: 13px;
  color: #666;
  margin-bottom: 10px;
}

.modal-header {
  position: absolute;
  top: 10px;
  right: 10px;
  padding:0;
  z-index: 10; /* Ensure the close button is above other elements */
}

.modal-body apply-button-container{
  padding: 10px;
  display: flex;
}

.close-button {
  font-size: 24px;
  background: transparent;
  border: none;
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  color: #333;
  font-weight: bold;
}

.close-button .material-icons {
  font-size: 30px; /* Set size of the X icon */
  color: #333;
  transition: color 0.3s ease;
}

.application-modal{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.application-modal .form-container label{
  font-size: 12px;
  color: #333;
}

.application-modal .form-container textarea{
  height:75px;
}